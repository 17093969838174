import React from "react";

import FooterMenu from "./footerMenu";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="pt-15 md:pb-32 lg:pb-40 px-ogs">
        <FooterMenu />
      </div>
      <div className="flex justify-center pb-15">
        <span className="text-xs">&copy; {currentYear}</span>
      </div>
    </footer>
  );
};

export default Footer;
