import React from "react";
import TransitionLink from "../TransitionLink";

const Link = ({ children, link, text, className }) => {
  if (!(link && link.url)) {
    return null;
  }

  if (link.link_type === "Document") {
    return (
      <TransitionLink to={link.url} className={className}>
        {children ? children : text}
      </TransitionLink>
    );
  } else {
    return (
      <a href={link.url} className={className} target="_blank" rel="noreferrer">
        {children ? children : text}
      </a>
    );
  }
};

export default Link;
