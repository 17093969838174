import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Link from "../Link";

const FooterMenu = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          prismicGlobal {
            data {
              footer_menu {
                title
                link {
                  link_type
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="flex flex-col md:flex-row text-center md:justify-between md:pt-12 -mx-2">
          {data.prismicGlobal.data.footer_menu &&
            data.prismicGlobal.data.footer_menu.map((item, index) => {
              return (
                <Link
                  key={index}
                  link={item.link}
                  text={item.title}
                  className="px-2 mb-20 md:mb-0 transition-opacity transition-ease duration-300 hover:opacity-50"
                />
              );
            })}
        </div>
      )}
    />
  );
};

export default FooterMenu;
