import React from "react";
import TransitionLink from "../TransitionLink";

const BackLink = ({ pageTheme, to, displayText, is404 }) => {
  const textColor = pageTheme === "dark" ? "text-white" : "text-black";
  const borderColor = pageTheme === "dark" ? "border-white" : "border-black";
  if (is404) {
    displayText = "Home";
  } else if (!displayText) {
    displayText = "Back";
  }
  return (
    <div className="flex justify-center md:justify-start">
      <TransitionLink
        to={to}
        className={`flex items-center text-base transition-opacity duration-300 hover:opacity-50 ${textColor}`}
      >
        <span
          className={`inline-block w-24 border border-px mr-4 ${borderColor}`}
        ></span>
        {displayText}
      </TransitionLink>
    </div>
  );
};

export default BackLink;
