import React from "react";
import Logo from "../Logo";
import BackLink from "../BackLink";
import TransitionLink from "../TransitionLink";

const Header = ({ pageTheme, backLink, is404 }) => {
  const textColor = pageTheme === "dark" ? "text-white" : "text-black";
  return (
    <div className="py-12 md:py-16 lg:py-20 px-ogs">
      <div className="flex flex-wrap justify-center relative">
        <h1 className={`${backLink ? "mb-12 md:mb-0" : ""}`}>
          <TransitionLink to="/" aria-label="Petra Mingneau">
            <Logo textColor={textColor}></Logo>
          </TransitionLink>
        </h1>
        {backLink && (
          <div className="md:absolute left-0 inset-y-0 h-full w-full md:w-auto flex items-center justify-center">
            <BackLink pageTheme={pageTheme} to={backLink} is404={is404} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
