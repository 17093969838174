import React from "react";

const Logo = ({ textColor }) => (
  <div className="w-41 md:w-48 lg:w-52">
    <svg
      className={`block w-full fill-current ${textColor}`}
      viewBox="0 0 226.3 108.68"
    >
      <path d="M21.4,41.35,14,58.89,6.68,41.35H0V67.09H5V48.7l6.68,16.22h4.81L23.12,48.7V67.09h4.95V41.35Zm36.66,0h5.17V67.09H58.06Zm40.19,8.24v17.5H93.14V41.35h5.25l11,17.65V41.35h5.1V67.09h-5.13Zm68.22-4.45-3.27,3.38a9,9,0,0,0-6.64-2.83c-5,0-8.15,3.75-8.15,8.53,0,5.26,3.52,8.49,8.15,8.49a8.92,8.92,0,0,0,6.16-2V57.53h-4.88V53.15h9.73V63.34a15.77,15.77,0,0,1-11.23,4.22A12.92,12.92,0,0,1,143,54.22a13,13,0,0,1,13.32-13.35A13.28,13.28,0,0,1,166.47,45.14ZM68.85,90.7v17.5h-5.1V82.47H69l11,17.64V82.47h5.1V108.2H79.93ZM115,82.47h13.21v4.67h-8v5.73h6.9v4.59h-6.9v6.11h8.37v4.63H115ZM167.33,88l-3,9.26h6ZM163,101.58l-2.24,6.62h-5.21l9.1-25.73H170l9.1,25.73h-5.21l-2.23-6.62Zm42.64-3V82.47h5.18V98.53c0,3.27,1.87,5.33,5.17,5.33s5.18-2.06,5.18-5.33V82.47h5.17V98.53c0,6.07-3.71,10.15-10.35,10.15S205.6,104.6,205.6,98.53ZM35.51,11.65A3.54,3.54,0,0,0,39.32,8a3.46,3.46,0,0,0-3.74-3.52H33.19v7.16ZM28,0h8.19c5,0,8.44,3.16,8.44,8s-3.56,8.13-8.41,8.13h-3v9.59H28ZM72.34,0H85.56V4.67h-8V10.4h6.9V15h-6.9v6.1h8.36v4.63H72.34Zm46.57,4.67h-6V0h17.18V4.67h-6V25.73h-5.18Zm46.34,6.62a3.32,3.32,0,0,0,3.53-3.46c0-2.06-1.43-3.34-3.78-3.34h-2.17v6.8ZM157.66,0h7.74c5.62,0,8.7,3.42,8.7,7.65a7.48,7.48,0,0,1-3.89,6.8l6.09,11.28h-5.72l-5-10.07h-2.75V25.73h-5.17Zm56.77,5.55-3,9.27h6Zm-4.36,13.57-2.24,6.61h-5.21L211.72,0h5.43l9.1,25.73H221l-2.24-6.61Z" />
    </svg>
  </div>
);

export default Logo;
